import htmx from 'htmx.org';
window.htmx = htmx

import * as Popper from '@popperjs/core'
window.Popper = Popper

import TomSelect from 'tom-select'
window.TomSelect = TomSelect

import * as easepick from '@easepick/bundle'
window.easepick = easepick

import '../sass/app.scss'
import * as bootstrap from 'bootstrap'
window.bootstrap = bootstrap
// import Alert from 'bootstrap/js/dist/alert'
import Tooltip from 'bootstrap/js/dist/tooltip'
// import Toast from 'bootstrap/js/dist/toast'
import Popover from 'bootstrap/js/dist/popover'



import '@iconify/iconify'
import 'pwacompat'

import '../assets/js/custom.jsx'
